<template>
  <div class="container">
    <section class="top">
      <div class="row">
        <div class="cols-12">
          <h2>RETIRADA DO BEM ARREMATADO</h2>
          <br />
          <p>
            A responsabilidade da entrega do bem é do comitente e ou da promotora de vendas do leilão.
          </p>
          <p>
            A Plataforma EBL Online é tão somente a intermediadora do comprador e o vendedor, isentando-se de qualquer responsabilidade da entrega do bem.
          </p>
          <br />
           <p>
            Fornecemos uma ferramenta para agendamento para retirada do bem arrematado e pago. 
            Após a confirmação do pagamento do lote arrematado, A EBL Online enviará por email o periodo liberado para realização do agendamento da retirada. 
           <br/><b>Obs.: A Nota e o Auto de Arrematação serão entregues no momento da retirada do bem</b>
          </p>
          <p>
            Passo a passo para agendar retirada do lote.
            <ol>
              <li>Entre em sua conta (<a href="https://eblonline.com.br/login" target="_blank">https://eblonline.com.br/login</a>) com email e senha</li>
              <li>Entre no seu perfil, clicando no seu nome no topo da página.</li>
              <li>No menu lateral, entre em Lotes Arrematados.</li>
              <li>Nesta tela irá mostrar todos os lotes arrematados, clique no botão Agendar do lote desejado e escolha a data e hora da retirada e confirme.</li>
              <li>Após a confirmação irá mostrar o comprovante de agendamento, imprima ou salve em PDF pois deve ser apresentado no dia da retirada.</li>
            </ol>           
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top {
  padding: 200px 20px 0;
}
</style>